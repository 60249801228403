.noti-icon .badge {
  left: 23px;
}

.mt-2 {
  margin-top: 20px !important;
}

.dataTables_filter, .dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}

.dz-message {
  text-align: center;
  padding: 100px;
}

.fcYNFc {
  background-color: #ffffff !important;
}

.task-box {
  border: 1px solid #e1e1e6;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-dayGridDay-view .fc-event .fc-content {
  padding: 5px;
  color: #FFF;
  word-break: break-word;
  white-space: pre-wrap;
  display: flex;
  flex-direction: row;
}


.fc-dayGridDay-view .fc-day-grid-event .fc-time {
   display: block;
   float: left;
   word-break: keep-all;
   white-space: nowrap;
   padding: 5px;
 }

.fc-dayGridDay-view .fc-event .fc-title {
  display: block;
  float: right;
  text-align: left;
}

.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}

.form-error {
    margin-bottom: 20px;
    border-width: 1px 1px 1px 10px;
    border-style: solid;
    border-color: #bf1650 #bf1650 #bf1650 #ec5990 !important;
    border-image: initial;
    background: #fbecf2;
}

.datatable {
  font-size: 100%;
}
