//
// backgrounds.scss
//


@each $color,
$value in $theme-colors {
  .bg-soft-#{$color} {
    background-color: rgba(($value), 0.25) !important;
  }
}

.bg-light-blue {
  background-color: rgba(0, 122, 255, 1.0) !important;
}

.bg-light-teal {
  background-color: rgba(90, 200, 250, 1.0) !important;
}

.bg-light-orange {
  background-color: rgb(255, 185, 0) !important;
}

.bg-light-green {
  background-color: rgba(52, 199, 89, 1.0) !important;
}

.bg-light-red {
  background-color: rgba(255, 59, 48, 1.0) !important;
}

.bg-light-gray {
  background-color: rgba(142, 142, 147, 1.0) !important;
}

.bg-light-purple {
  background-color: rgba(175, 82, 222, 1.0) !important;
}

.alert-light-blue {
  color: rgba(255, 255, 255, 1.0);
  font-weight: bold;
  background-color: rgba(0, 122, 255, 0.6) !important;
  border-color: rgba(0, 122, 255, 0.6) !important;
}

.alert-light-teal {
  color: rgba(255, 255, 255, 1.0);
  font-weight: bold;
  background-color: rgba(90, 200, 250, 0.6) !important;
  border-color: rgba(90, 200, 250, 0.6) !important;
}

.alert-light-orange {
  color: rgba(255, 255, 255, 1.0);
  font-weight: bold;
  background-color: rgba(255, 185, 0, 0.6) !important;
  border-color: rgba(255, 185, 0, 0.6) !important;
}

.alert-light-green {
  color: rgba(255, 255, 255, 1.0);
  font-weight: bold;
  background-color: rgba(52, 199, 89, 0.6) !important;
  border-color: rgba(52, 199, 89, 0.6) !important;
}

.alert-light-red {
  color: rgba(255, 255, 255, 1.0);
  font-weight: bold;
  background-color: rgba(255, 59, 48, 0.6) !important;
  border-color: rgba(255, 59, 48, 0.6) !important;
}

.alert-light-gray {
  color: rgba(255, 255, 255, 1.0);
  font-weight: bold;
  background-color: rgba(142, 142, 147, 0.6) !important;
  border-color: rgba(142, 142, 147, 0.6) !important;
}

.alert-light-purple {
  color: rgba(255, 255, 255, 1.0);
  font-weight: bold;
  background-color: rgba(175, 82, 222, 0.6) !important;
  border-color: rgba(175, 82, 222, 0.6) !important;
}
